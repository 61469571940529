<template>
  <div>
    <lexicalGrammar></lexicalGrammar>
  </div>
</template>
<script>
import lexicalGrammar from "@/components/lexicalGrammarPractice.vue";
export default {
  components: {
    lexicalGrammar
  },
  computed: {},
  watch: {},
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {

  },
  beforeDestroy () {
  }
};
</script>
<style lang="less" scoped>
</style>
