<template>
  <div class="lexicalGrammar">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="init"
      v-if="list.length > 0"
    >
      <div class="cont" v-for="(g, i) in list" :key="i">
        <div class="title">
          {{ g.QuestionTitle }}
        </div>
        <el-radio-group v-model="g.StudentAnswerStr" disabled>
          <p v-for="(b, i) in g.BankItem" :key="i">
            <el-radio :label="b.ItemNum">{{ b.ItemNum }}.{{ b.ItemTitle }}</el-radio>
          </p>
        </el-radio-group>
        <div>
          <p :class="g.StudentAnswerStr === g.AnswerStr ? 'hdzq' : 'hdcw'">
            {{ g.StudentAnswerStr === g.AnswerStr ? "回答正确" : "回答错误" }}{{value}}
          </p>
          <p class="analysis">
            <span class="fw-bold">【答案】</span>
            <span :class="g.StudentAnswerStr === g.AnswerStr ? 'hdzq' : 'hdcw'">
              {{ g.AnswerStr }}
            </span>
          </p>
          <p class="answer">
            <span class="fw-bold">【解析】</span>
            <span>{{ g.QuestionAnalysis }} </span>
          </p>
        </div>
      </div>
    </van-list>
    <div v-else>
      <noData></noData>
    </div>
  </div>
</template>

<script>
import {
  queryGrammar,
  qrammarSave,
  queryGrammarRecord
} from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  components: { noData },
  computed: {},
  watch: {},
  data () {
    return {
      value: "",
      pageIndex: 1,
      pageSize: 10,
      route: {},
      grammar: {},
      show: false,
      history: false,
      list: [],
      loading: false,
      finished: false,
    };
  },
  mounted () {
    // exercises 0 习题中心 1练习记录
    this.route = this.$route.query
    this.init()
  },
  methods: {
    goHistory () {
      this.$router.go(-1)
    },
    next () {
      this.init()
      this.value = ""
      this.show = false
    },
    async handleSubmit () {
      if (!this.value) {
        this.$message.warning("请选择");
        return false
      }
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        questionId: this.grammar.QuestionId,
        studentAnswer: this.value
      }
      const res = await qrammarSave(parm);
      if (res.success == true) {
        this.show = true
      }
    },
    async init () {
      let parm = "?studentId=" + this.route.UserID +
        "&courseId=" + this.route.courseId +
        "&pageIndex=" + this.pageIndex +
        "&pageSize=" + this.pageSize
      const res = this.route.exercises == 0 ?
        await queryGrammar(parm) :
        await queryGrammarRecord(parm)
      if (res.success == true) {
        this.list = this.list.concat(res.response.data);
        this.pageIndex++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      }
    },
    handChange () {
    }
  }
};
</script>
<style lang="less" scoped>
.lexicalGrammar {
  min-height: 100vh;
  .cont {
    background-color: #fff;
    padding: 0 30px 20px;
    margin-bottom: 10px;
    .title {
      line-height: 100%;
      padding: 15px 0;
      font-size: 15px;
      font-weight: bold;
    }
    .hdzq {
      font-weight: bold;
      font-size: 15px;
      color: #3a78f9;
      margin-top: 10px;
    }
    .hdcw {
      font-weight: bold;
      font-size: 15px;
      color: #fe4a4a;
      margin-top: 10px;
    }
    .analysis,
    .answer {
      font-size: 15px;
    }
  }
  .el-radio-group,
  .el-checkbox-group {
    .el-checkbox {
      // display: flex;
      // align-items: center;
    }
    .el-radio,
    .el-radio__input,
    .el-checkbox,
    .el-checkbox__input {
      white-space: normal !important;
      line-height: 175%;
    }
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
